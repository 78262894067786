


















































import Vue from 'vue';
import { ApiResource } from '@/types';

export default Vue.extend({
  components: {
    MediaCommissionItem: () => import(/* webpackChunkName: "m-c-i" */ '@/components/media/CommissionItem.vue'),
  },
  props: {
    item: {
      type: Object as () => ApiResource.CommissionItem,
      required: true,
      validator(obj: any): obj is ApiResource.CommissionItem {
        return obj.object === 'commission_item';
      },
    },
  },
  data() {
    return {
      loading: false,
      previewing: false,
    };
  },
  methods: {
    preview() {
      this.previewing = true;
    },
    cancel() {
      this.loading = false;
      this.previewing = false;
    },
    handleError(e: Error) {
      this.$emit('item-pay-error', e);
    },
    async pay() {
      this.loading = true;

      const stripe = await this.$stripe()
        .catch(this.handleError);

      if (stripe === undefined) return this.cancel();

      const data = await this.$store.dispatch('commission/item/Pay', {
        COMMISSION_ID: this.item.commission_id,
        ITEM_ID: this.item.id,
        // form: {
        //   is_forfeiting_cancellation_right: false,
        // },
      }).catch(this.handleError);

      if (!data) return this.cancel();

      const sessionId = data.data.id;

      this.$emit('redirect-to-checkout', this.item);

      await stripe.redirectToCheckout({ sessionId })
        .catch(this.handleError);

      return this.cancel();
    },
  },
});
